import React from 'react'

import { packageMode, setCurrency, unitMode } from '../../../../common/cardUtils'
import { WITHOUT_PRICE } from '../../../../common/constants'
import { useAppSelector } from '../../../../common/hooks'
import { showPrices } from '../../../../common/utils'
import { getUnitPerPackagePrice } from '../../../../common/productUtils'
import { Product } from '../../../../store/data/types'

import {
  StyledContainerDiscount,
  StyledCurrency,
  StyledContainerValues,
} from './styles/CardPriceInfo.style'
import {
  StyledContainerPackage,
  StyledContainerPricesPackage,
  StyledModePackage,
  StyledPricePackage,
} from './styles/PackagePriceInfo.style'

interface PackagePriceInfoProps {
  mode?: string
  warning: boolean
  product: Product
  handleClick: any
}

const PackagePriceInfo = ({ mode, warning, product, handleClick }: PackagePriceInfoProps) => {
  const { discount, packageNumberOfItems, packagePrice } = product

  const { merchantCode } = useAppSelector((state) => state.session)

  const isPackageSelected = mode === packageMode
  const isUnitSelected = mode === unitMode
  const isPromotion = !!discount
  const [firstSupplier] = product.supplierPrices

  const supplierPackagePrice = firstSupplier.packagePrice

  const { getMoreForLess } = firstSupplier

  const unitPerPackagePromoPriceWithoutDiscount = getUnitPerPackagePrice(
    packagePrice,
    packageNumberOfItems
  )

  const getPackagePriceToShow = () => {
    let packagePriceToshow = supplierPackagePrice

    if (getMoreForLess) {
      packagePriceToshow = getMoreForLess.priceWithoutPromotion
    }

    return packagePriceToshow
  }

  const unitPerPackagePrice = getUnitPerPackagePrice(getPackagePriceToShow(), packageNumberOfItems)

  const finalPackagePrice = showPrices(merchantCode)
    ? setCurrency(unitPerPackagePrice)
    : WITHOUT_PRICE

  return (
    <StyledContainerPackage
      onClick={handleClick}
      id="package-selector"
      isPackageSelected={isPackageSelected}
      isUnitSelected={isUnitSelected}
      warning={warning}
    >
      <StyledModePackage isPackageSelected={isPackageSelected} warning={warning}>
        <span>Caixa</span> {packageNumberOfItems} un.
      </StyledModePackage>
      <StyledContainerPricesPackage isPackageSelected={isPackageSelected}>
        <StyledContainerValues isUnitSelected={isUnitSelected}>
          <StyledCurrency fontSizeMobile={'0.5rem'}>R$</StyledCurrency>
          <StyledPricePackage isUnitSelected={isUnitSelected} data-testid="unit-price-in-package">
            {finalPackagePrice}
          </StyledPricePackage>
          <span>/un.</span>
          {isPromotion && (
            <StyledContainerDiscount>
              <span>R${setCurrency(unitPerPackagePromoPriceWithoutDiscount)}</span>
            </StyledContainerDiscount>
          )}
        </StyledContainerValues>
      </StyledContainerPricesPackage>
    </StyledContainerPackage>
  )
}

export default PackagePriceInfo
