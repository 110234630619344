import { clubbiStyled as styled } from 'clubbi-ui'

export const StyledContainerPrices = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(1.752),
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  color: theme.palette.brandGrey[60],
}))

export const StyledContainerValues = styled('div')<{ isUnitSelected: boolean }>(
  ({ theme, isUnitSelected }) => ({
    display: 'flex',
    alignItems: 'flex-end',
    height: '100%',
    paddingBottom: isUnitSelected ? 0 : '4px',
    [theme.breakpoints.up('sm')]: {
      paddingBottom: isUnitSelected ? '4px' : '6px',
    },
  })
)

export const StyledContainerDiscount = styled('div')(({ theme }) => ({
  color: theme.palette.brandGrey[50],
  fontSize: theme.typography.fontSize3XS,
  display: 'flex',
  textDecoration: 'line-through',
  marginLeft: '2px',
  [theme.breakpoints.up('sm')]: {
    fontSize: theme.typography.fontSizeXXS,
  },
}))

export const StyledCurrency = styled('span')<{ fontSizeMobile: string }>(({ theme }) => ({
  fontSize: theme.typography.fontSize2XS,
  [theme.breakpoints.up('sm')]: {
    fontSize: theme.typography.fontSizeXXS,
  },
}))
