import { ClubbiCheckCircleIcon, themeClubbi } from 'clubbi-ui'
import { useLocation } from 'react-router-dom'

import { getSaveQuantity, setCurrency } from '../../../common/cardUtils'
import { Product } from '../../../store/data/types'

import {
  StyledContainerInformations,
  StyledContainerText,
  StyledRedText,
  StyledSavedText,
} from './styles/GetMoreForLessInformations.style'

interface InformationsProps {
  product: Product
  quantity: number
}

export const GetMoreForLessInformation = ({ product, quantity }: InformationsProps) => {
  const { supplierPrices, packageNumberOfItems } = product
  const [{ getMoreForLess }] = supplierPrices
  const { quantityToGet, promotionalPrice, priceWithoutPromotion, isPackageMode } = getMoreForLess!
  const location = useLocation()

  return (
    <StyledContainerInformations isPackageMode={isPackageMode}>
      {location.pathname !== '/cart' && (
        <>
          <span>No combo levando </span>
          <strong>{`${quantityToGet}${isPackageMode ? 'cx.' : 'un.'}`}</strong>
          <span> cada unidade sai por </span>
          <StyledRedText>{`R$${setCurrency(
            getMoreForLess!.promotionalPrice / (isPackageMode ? packageNumberOfItems! : 1)
          )}`}</StyledRedText>
        </>
      )}
      {quantity >= quantityToGet && (
        <StyledContainerText>
          <ClubbiCheckCircleIcon
            color={themeClubbi.palette.brandSuccess[70]}
            size={themeClubbi.typography.fontSizeXXS}
          />
          <StyledSavedText>
            <span>Economia de </span>
            <strong>{`R$${setCurrency(
              getSaveQuantity(quantity, quantityToGet, promotionalPrice, priceWithoutPromotion)
            )}`}</strong>
          </StyledSavedText>
        </StyledContainerText>
      )}
    </StyledContainerInformations>
  )
}
