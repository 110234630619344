import { clubbiStyled } from 'clubbi-ui'

export const StyledContainerInformations = clubbiStyled('div')<{
  isPackageMode: boolean | undefined
}>(({ theme, isPackageMode }) => ({
  fontSize: theme.typography.fontSize2XS,
  lineHeight: '13px',
  width: '144px',
  height: '30px',
  padding: 0,
  marginTop: isPackageMode ? '-5px' : '5px',
  color: theme.palette.brandGrey[100],

  [theme.breakpoints.up('sm')]: {
    fontSize: theme.typography.fontSizeXXS,
    lineHeight: '17px',
    width: '210px',
    height: '35px',
    marginTop: isPackageMode ? '0px' : '14px',
  },
}))

export const StyledRedText = clubbiStyled('strong')(({ theme }) => ({
  color: theme.palette.brandAlert[50],
}))

export const StyledContainerText = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
  height: '25px',
  gap: '5px',
  marginTop: '-8px',
  zIndex: '10',

  [theme.breakpoints.up('sm')]: {
    marginTop: 0,
  },
}))

export const StyledSavedText = clubbiStyled('span')(({ theme }) => ({
  fontSize: theme.typography.fontSize2XS,

  color: theme.palette.brandSuccess[70],

  [theme.breakpoints.up('sm')]: {
    fontSize: theme.typography.fontSizeXXS,
  },
}))
