import { clubbiStyled } from 'clubbi-ui'

export const StyledBadgeContainer = clubbiStyled('button')(({ theme }) => ({
  backgroundColor: theme.palette.brandPurple[60],
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  borderRadius: '20px',
  width: '235px',
  height: '18px',
  paddingLeft: '4px',
  border: 'none',

  [theme.breakpoints.up('md')]: {
    width: '310px',
    height: '25px',
    padding: '6px',
  },
}))

export const StyledBadgeText = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  gap: '3px',
  whiteSpace: 'nowrap',

  fontWeight: 400,
  fontSize: theme.typography.fontSize3XS,
  color: theme.palette.brandWhite.main,

  [theme.breakpoints.up('md')]: {
    fontSize: theme.typography.fontSizeXS,
  },
}))
