import { clubbiStyled as styled } from 'clubbi-ui'

export const StyledContainerUnitPrice = styled('div')<{ isUnitSelected: boolean }>(
  ({ theme, isUnitSelected }) => ({
    color: theme.palette.brandGrey[100],
    display: 'flex',
    alignItems: 'flex-end',
    height: '100%',
    marginTop: isUnitSelected ? '0' : '4px',
    fontSize: theme.typography.fontSize2XS,
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.fontSizeXXS,
    },
  })
)

export const StyledContainerUnit = styled('div')<{
  isUnitSelected: boolean
  visible: number | boolean
  warning: boolean
}>(({ theme, isUnitSelected, visible, warning }) => ({
  width: '146px',
  height: isUnitSelected ? '48px' : '40px',
  position: 'relative',
  border: warning
    ? `1px solid ${theme.palette.brandAlert[50]}`
    : isUnitSelected
    ? `1px solid ${theme.palette.brandPurple[60]}`
    : `1px solid ${theme.palette.brandGrey[20]}`,
  borderRadius: '4px',
  cursor: visible ? 'pointer' : 'auto',
  visibility: visible ? 'visible' : 'hidden',
  transitionDuration: '0.3s',

  [theme.breakpoints.up('sm')]: {
    width: '218px',
    height: isUnitSelected ? '58px' : '48px',
  },
}))

export const StyledModeUnit = styled('div')<{
  isUnitSelected: boolean
  warning: boolean
  visible: number | boolean
}>(({ theme, isUnitSelected, warning, visible }) => ({
  color: warning
    ? theme.palette.brandWhite.main
    : isUnitSelected
    ? theme.palette.brandWhite.main
    : theme.palette.brandGrey[60],
  fontSize: theme.typography.fontSize3XS,
  backgroundColor: warning
    ? theme.palette.brandAlert[50]
    : isUnitSelected
    ? theme.palette.brandPurple[60]
    : theme.palette.brandGrey[20],
  borderRadius: '0 0 2px 2px',
  height: '13px',
  position: 'absolute',
  top: '-1px',
  left: '8px',
  padding: '1px 5px',
  lineHeight: 1,
  weight: 600,
  visibility: visible ? 'visible' : 'hidden',
  transitionDuration: '0.3s',

  [theme.breakpoints.up('sm')]: {
    fontSize: theme.typography.fontSizeXXS,
    height: '16px',
  },
}))

export const StyledContainerPricesUnit = styled('div')(({ theme }) => ({
  color: theme.palette.brandGrey[100],
  lineHeight: 1.2,
  padding: '8px',
  display: 'flex',
  alignContent: 'flex-end',
  height: '100%',
  [theme.breakpoints.up('sm')]: {
    padding: '12px',
  },
}))

export const StyledPriceUnit = styled('span')<{ isUnitSelected: boolean }>(
  ({ theme, isUnitSelected }) => ({
    fontSize: isUnitSelected ? theme.typography.fontSizeM : theme.typography.fontSizeXXS,
    marginBottom: isUnitSelected ? '-2px' : 0,
    fontWeight: 600,
    padding: 0,
    transitionDuration: '0.3s',

    [theme.breakpoints.up('sm')]: {
      fontSize: isUnitSelected ? theme.typography.fontSizeXXL : theme.typography.fontSizeS,
      marginBottom: isUnitSelected ? '-6px' : '-2px',
    },
  })
)
