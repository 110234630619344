export interface Category {
  name: string
  slug: string
  parent?: Category
}

export interface GetMoreForLess {
  quantityToPurchase: number
  quantityToGet: number
  promotionalPrice: number
  priceWithoutPromotion: number
  limit: number
  datetimeToStart: string | number | Date
  datetimeToExpire: string | number | Date
  isPackageMode: boolean
}

export interface SupplierPrice {
  supplierName: string
  price: number
  packagePrice: number
  datetimeToExpire?: string | number | Date | undefined
  datetimeToStart?: string | number | Date | undefined
  supplierId: string
  inStockQuantity?: number
  availableQuantity?: number
  offerType?: number | undefined
  unitOfferId?: number | undefined
  limitPerOrder?: number | undefined
  supplierType?: string | undefined
  isOfertao?: boolean
  packageQtd?: number
  isAvailable?: boolean
  limitQuantity?: number
  isPriority?: boolean
  getMoreForLess?: GetMoreForLess
}

export interface SponsoredInfo {
  clickUrl: string
  impressionUrl: string
  viewUrl: string
}

export interface Product {
  id: string
  ean: string
  datetimeToExpire?: string | number | Date | undefined
  packageEan: string
  imageUrls: {
    image120Px: string
    image300Px: string
  }
  description: string
  category: Category
  price: number
  discount?: number | undefined
  originalPrice?: number | undefined
  limitPerOrder?: number
  supplierPrices: SupplierPrice[]
  packageNumberOfItems?: number
  packagePrice?: number
  inOfertao?: boolean
  inPromotion?: boolean
  inStock?: boolean
  relatedPrices?: string[]
  sponsoredInfo?: SponsoredInfo | null
  productExpirationDate?: string | undefined
}

export interface CartChange {
  context?: string
  quantity: number
  previousQuantity?: number
  changeDatetime?: string
}

export interface CartItem {
  product: Product
  quantity: number
  isPackageMode: boolean
  changes: CartChange[]
  isAvailable?: boolean
  storeId?: string
  url?: string
  corridor?: string
  simuledPrice?: number
}

export interface Cart {
  [key: string]: CartItem
}

export enum OrderStatus {
  PENDING = 'pending',
  PLANNED = 'planned',
  COMPLETED = 'completed',
  CANCELLED = 'cancelled',
  DEMOIGNORE = 'demo-ignore',
  TOTALRETURN = 'total-return',
  PARCIALRETURN = 'parcial-return',
  DELIVERED = 'delivered',
  CREDITREVIEW = 'credit-review',
}
export interface Purchase {
  id: string
  orderDatetime: string
  paymentType: number
  itemsData: CartItem[]
  status: OrderStatus
}

export enum PaymentType {
  BOLETO = 'Boleto',
  CARTAO = 'Cartão de crédito',
  PIX = 'Pix',
}

export interface CategoryProducts extends Category {
  products: Product[]
}

export interface SectionCategories extends Category {
  categories: CategoryProducts[]
}

export interface GroupListing {
  title?: string
  products: Product[]
  creationDatetime: string
  expirationDate: string
}

export interface LinkData {
  text: string
  href: string
  images?: string[]
}

export interface Offer {
  name: string
  link: LinkData
  products: Product[]
  context?: string | undefined
  missions?: Mission[]
}

export type AlgoliaEvent = {
  eventType: string
  eventName: string
  userToken: string
  timestamp: number
  objectIds: string[]
  queryId?: string
  positions?: number[]
}

export interface CustomCorridor {
  id: string
  label: string
  images: string[]
  products: Product[]
  isPartner: boolean
  endTime?: string
}

export interface SpecialSupplier {
  supplierId: string
  images: string[]
  products: Product[]
  label: string
}

export interface Tag {
  id: string
  name: string
}

export interface TagProducts {
  id: number
  name: string
  parent_id: number | null
  type: string // maybe other
  products: Product[]
}

export type DayOff = {
  weekday: number | null
  date: string
  nextBusinessDayIsDayOff: string
  specialDayOffOnTuesday: boolean
  nextDeliveryDay: string
  nextDeliveryDayWeekday: number
}

export interface Popup {
  showModal: boolean
  today: string
  dayOff: DayOff
}

export interface BlockedBanner {
  showBanner: boolean
}

export interface AllowedPurchaseBanner {
  showBanner: boolean
}

export interface Rating {
  id: number
  shopperPlanId: string
  ratingValue?: number | null
  creationDatetime: string
  shown: boolean
}

export interface InvoiceSummary {
  billingValue: number
  creationDatetime: string
  deliveryFinishDatetime: string
  leftForDeliveryDatetime: string
  dueDate: string
  invoiceType: number
  paymentType: number
  shopperPlanId: string
  status: string
  totalDeliveredPrice: number
  totalPrice: number
  url: string
  billingUrl: string
  billingStatus: string
  orderId?: number[]
  nfeUrls: string[]
  invoiceDiscount?: number | null
  rating?: Rating | null
  supplier?: string[]
}

export interface MissionEan {
  active: boolean
  ean: string
}

export interface MissionCustomer {
  active: boolean
  customerId: string
  completed: boolean
  selected: boolean
}

export interface Mission {
  id: number
  description: string
  type: string
  criteria: string
  discountAmount?: number | undefined
  minOrderAmount?: number | undefined
  ranking?: number | undefined
  unlimitedCompletions: boolean
  eans: MissionEan[]
  customerIds: MissionCustomer[]
  expireAt?: string | number | Date | undefined
  updatedAt: string | number | Date
}

export interface CategoriesParent {
  id: number
  name: string
  parent?: null
  slug: string
}

export interface Categories {
  id: number
  level: number
  name: string
  parent?: CategoriesParent | null
  slug: string
  order: number
}

export interface Banner {
  adId: string
  mediaUrl: string
  mediaUrlMobile: string
  clickUrl: string
  impressionUrl: string
  viewUrl: string
  products: Product[]
  destinationUrl: string
}

export interface Credit {
  remainingCredit: number
  initialCredit: number
}
export interface Link {
  text: string
  href: string
}

export interface OfferSection {
  name: string
  link: Link
}

export interface Section {
  context: string
  name: string
  link: Link
  order: number
  products: Product[]
}

export interface ProductsSection {
  section: Section[]
}

export type Corridor = {
  term: string
  products: Product[]
}

export type BannerSearchProducts = {
  products: Product[]
}

export type Merchant = {
  merchantCode: string
  merchantName?: string | null
  userName?: string | null
  email?: string | null
  paymentResponsiblePhone?: string | null
  financeEmail?: string | null
  mainContact?: string | null
  fullAddress?: string | null
}

export type MerchantDeliveryRestriction = {
  deliveryTimeStart?: string | null
  deliveryTimeFinish?: string | null
  deliveryBreakTimeStart?: string | null
  deliveryBreakTimeFinish?: string | null
  saturdayClosure?: string | null
  deliveryDaysRestriction?: number[] | null
  hasQueue?: boolean | null
  preferredVehicles?: number[] | null
  additionalInformation?: string | null
}

export enum ContactType {
  MAIN_WHATSAPP = 'main_whatsapp',
  FINANCIAL = 'financial',
  PURCHASE = 'purchase',
  RECEIPT = 'receipt',
  OTHERS = 'others',
}

export type MerchantContact = {
  phone: string
  mainContact?: boolean | null
  contactType?: ContactType | null
}

export type MerchantProfileData = {
  merchant: Merchant
  merchantContacts?: MerchantContact[] | null
  merchantDeliveryRestriction?: MerchantDeliveryRestriction | null
  hasBreak?: boolean
}

export type UpdateMerchantPayload = {
  paymentResponsiblePhone?: string | null
  financeEmail?: string | null
  mainContact?: string | null
}

export type Mode = {
  ean: boolean
}

export type Profitabilities = {
  marginByKg: number
  lc1: number
  lc1Percent: number
}

export type ProductProfitabilities = {
  [key: string]: Profitabilities | undefined
}

export type ProfitabilitiesPayload = {
  total: Profitabilities
  products: ProductProfitabilities[]
}
