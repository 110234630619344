import { StyleContainerTag } from './styles/GetMoreForLessTag.style'

export interface Props {
  quantityToGet: number
  quantityToPurchase: number
  isPackageMode: boolean
}

export const GetMoreForLessTag = ({ quantityToGet, quantityToPurchase, isPackageMode }: Props) => {
  return (
    <StyleContainerTag>{`Leve ${quantityToGet}${
      isPackageMode ? 'cx.' : 'un.'
    } Pague ${quantityToPurchase}`}</StyleContainerTag>
  )
}
