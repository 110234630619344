import { useCallback, useEffect, useState, MouseEvent } from 'react'
import { useInView } from 'react-intersection-observer'
import { useDispatch } from 'react-redux'
import { Collapse } from 'clubbi-ui'

import { CardDetails } from './CardDetails'
import { CardInfos } from './CardInfos'
import CardPriceInfo from './CardPriceInfo'
import { QuantityInput } from './QuantityInput'
import { TagPromotions } from './TagPromotions'
import { AdditionalInfoCard } from '../../../atoms/AdditionalInfoCard'
import { DiscountBadge } from '../../../molecules/DiscountBadge/DiscountBadge'
import { GetMoreForLessTag } from '../../../molecules/getMoreForLessTag/GetMoreForLessTag'
import { EanSelect } from '../../../molecules/eanSelect/EanSelect'
import { SponsoredTag } from '../../../molecules/sponsoredTag/SponsoredTag'
import gtm from '../../../../common/gtmTracker'
import { useAppSelector } from '../../../../common/hooks'
import { notEnoughPackageInStock } from '../../../../common/productUtils'
import { getMaxLimitForMode } from '../../../../store/data/cartFunctions'
import { CartChange, Product } from '../../../../store/data/types'
import { cartActions } from '../../../../store/slices/cart'
import { sessionActions } from '../../../../store/slices/session'
import {
  TypeInfos,
  getFlagTitlePromotion,
  packageMode,
  unitMode,
} from '../../../../common/cardUtils'
import {
  DELAY_TO_HIDE_CARD,
  DELAY_TO_SHOW_CARD,
  TWO_DAYS_STORE,
  TAX_BENEFIT_LINK,
  TAX_BENEFIT_MESSAGE,
} from '../../../../common/constants'

import {
  StyledCard,
  StyledCardTitle,
  StyledCardWrapper,
  StyledDiv,
  StyledMediaAndBadgeContainer,
  StyledProductImage,
  StyledTwoDays,
} from './styles/ProductCard.style'
import TwoDaysTag from '../../../atoms/TwoDaysTag'

export interface CartItemControllerProps {
  product: Product
  showSpecialPrice?: boolean
  ctx?: string
  label?: string
}

export interface PagesProps {
  totalProducts?: number
  index?: number
  searchTerm?: string | null
}

export const ProductCard = ({
  product,
  label,
  index,
  totalProducts,
  searchTerm,
}: CartItemControllerProps & PagesProps) => {
  const dispatch = useDispatch()
  const { ref, inView } = useInView({ threshold: 0.6 })
  const { modeGenerator } = useAppSelector((state) => state.salesFlyer)
  const cartItem = useAppSelector((state) => state.cart[product.id])
  const [{ merchantCode, cartSessionId, clubberEmail }, eans] = useAppSelector((state) => [
    state.session,
    state.taxBenefitEans.eans,
  ])

  const [limitReached, setLimitReached] = useState(false)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [warning, setWarning] = useState(false)
  const [showComponent, setShowComponent] = useState(false)
  const [hasTriggeredEvent, setHasTriggeredEvent] = useState(false)
  const [quantity, setQuantity] = useState(cartItem?.quantity || 0)
  const initialTypeCardInfo = quantity !== 0 ? 'totalPrice' : 'hide'
  const [typeCardInfos, setTypeCardInfos] = useState<TypeInfos>(initialTypeCardInfo)

  const [firstSupplier] = product.supplierPrices
  const supplierPackagePrice = firstSupplier.packagePrice
  const supplierPrice = firstSupplier.price

  const getMoreForLess = firstSupplier.getMoreForLess

  const isTwoDays = firstSupplier.supplierId == TWO_DAYS_STORE

  const showDiscountBadge = product.discount && !getMoreForLess

  useEffect(() => {
    if (product.sponsoredInfo) {
      const data = {
        userId: merchantCode,
        sessionId: cartSessionId,
      }
      navigator.sendBeacon(product.sponsoredInfo.impressionUrl, JSON.stringify(data))
    }
  }, [])

  useEffect(() => {
    if (inView) {
      gtm.triggerProductImpression(product, merchantCode!, label, clubberEmail!)
      gtm.triggerScrollProductsOnPage(merchantCode, index! + 1, totalProducts, clubberEmail)
      if (label === 'search-page') {
        gtm.triggerSearch(product, merchantCode!, label, searchTerm, clubberEmail)
      }

      if (product.sponsoredInfo && !hasTriggeredEvent) {
        const data = {
          userId: merchantCode,
          sessionId: cartSessionId,
        }
        navigator.sendBeacon(product.sponsoredInfo.viewUrl, JSON.stringify(data))
        setHasTriggeredEvent(true)
      }
    }
  }, [inView])

  const maxLimit = getMaxLimitForMode(!!cartItem?.isPackageMode, product)

  const getMode = () => {
    if (firstSupplier.getMoreForLess) {
      return firstSupplier.getMoreForLess.isPackageMode ? packageMode : unitMode
    }
    if (cartItem) {
      return cartItem.isPackageMode ? packageMode : unitMode
    }
    if (!supplierPackagePrice && supplierPrice) {
      return unitMode
    }
    if (product.inOfertao || (supplierPackagePrice && !supplierPrice)) {
      return packageMode
    }
    if (notEnoughPackageInStock(product)) {
      return unitMode
    }
  }

  const selectedMode = getMode()

  useEffect(() => {
    if (quantity >= 1) {
      setTypeCardInfos('totalPrice')
    } else {
      setTypeCardInfos('hide')
    }
  }, [quantity])

  useEffect(() => {
    if (warning) {
      setTypeCardInfos('mode')
    }
    if (selectedMode && !quantity) {
      setTypeCardInfos('hide')
    }
  }, [warning, selectedMode])

  const cartChange = (changeType: string, quantity: number): CartChange => {
    const maxLimit = quantity >= getMaxLimitForMode(!!cartItem?.isPackageMode, product)
    setLimitReached(maxLimit)

    return {
      context: `${changeType}/Item`,
      quantity,
    }
  }

  const dispatchQuantity = useCallback((cartChange: CartChange) => {
    if (merchantCode) {
      dispatch(
        cartActions.setQuantity({ product, cartChange, merchantCode, section: label, clubberEmail })
      )
    } else {
      dispatch(sessionActions.showModal(true))
    }
  }, [])

  const hasAdditionalInfo = () => {
    const eanProduct = product.ean
    const includeEan = eans?.includes(eanProduct)
    if (includeEan) {
      return <AdditionalInfoCard message={TAX_BENEFIT_MESSAGE} url={TAX_BENEFIT_LINK} />
    }
    return <></>
  }

  const handlePopoverOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const initialQuantity = cartItem?.quantity || 0

  useEffect(() => {
    setQuantity(initialQuantity)
  }, [initialQuantity, selectedMode])

  useEffect(() => {
    if (limitReached) {
      setTimeout(() => {
        setShowComponent(true)
        setTypeCardInfos('limit')
      }, DELAY_TO_SHOW_CARD)

      setTimeout(() => {
        setShowComponent(false)
        setTypeCardInfos('totalPrice')
      }, DELAY_TO_HIDE_CARD)
    }
  }, [limitReached])

  const getTotalPrice = () => {
    const mode = getMode()
    if (!mode) return 0
    const price = mode === 'package' ? supplierPackagePrice : supplierPrice
    return quantity * price
  }

  return (
    <div ref={ref}>
      <StyledCardWrapper>
        {modeGenerator && <EanSelect product={product} />}
        <StyledCard cardTitle={getFlagTitlePromotion(product)} elevation={0}>
          <>
            <TagPromotions product={product} />
            <StyledMediaAndBadgeContainer>
              <StyledProductImage
                alt={product.description}
                src={product.imageUrls?.image300Px}
                onClick={handlePopoverOpen}
              />
              {product.sponsoredInfo && <SponsoredTag />}
              {showDiscountBadge && <DiscountBadge discount={product.discount!} />}
              {getMoreForLess && (
                <GetMoreForLessTag
                  quantityToGet={getMoreForLess.quantityToGet}
                  quantityToPurchase={getMoreForLess.quantityToPurchase}
                  isPackageMode={getMoreForLess.isPackageMode}
                />
              )}
              {isTwoDays && (
                <StyledTwoDays>
                  <TwoDaysTag direction="right" />
                </StyledTwoDays>
              )}
            </StyledMediaAndBadgeContainer>
            <StyledCardTitle onClick={handlePopoverOpen}>{product.description}</StyledCardTitle>
          </>
          <StyledDiv>
            <CardPriceInfo
              mode={getMode()}
              warning={warning}
              setWarning={setWarning}
              setLimitReached={setLimitReached}
              product={product}
              quantity={quantity}
            />
            <QuantityInput
              quantity={quantity}
              setQuantity={setQuantity}
              limitReached={limitReached}
              alertInput={showComponent}
              product={product}
              mode={getMode()}
              dispatchQuantity={dispatchQuantity}
              cartChange={cartChange}
              maxLimit={maxLimit}
              setWarning={setWarning}
            />
          </StyledDiv>
        </StyledCard>
        {!!anchorEl && (
          <CardDetails
            anchorEl={anchorEl}
            openPopover={!!anchorEl}
            handlePopoverClose={handlePopoverClose}
            title={product.description}
            image={product.imageUrls?.image300Px}
            additionalInfo={hasAdditionalInfo()}
          />
        )}
      </StyledCardWrapper>
      <Collapse
        orientation="vertical"
        in={typeCardInfos !== 'neutral'}
        collapsedSize={1}
        timeout={100}
        easing={{ enter: 'linear', exit: 'linear' }}
      >
        <CardInfos
          typeCardInfos={typeCardInfos}
          totalPrice={getTotalPrice()}
          mode={selectedMode}
          limit={maxLimit}
          quantity={quantity}
          product={product}
        />
      </Collapse>
    </div>
  )
}
